/* eslint-disable new-cap */
import { EcpAppliancePageApi } from './EcpAppliancePageApi';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { buildUrl as BuildUrl } from '@mattiadevivo/build-url';
import { BASE_URL } from '../config';
import {
    TelemetryEventResponse, ConnectivityEventResponse, ApplianceIdsResponse, ApplianceInformationResponse, ApplianceStateResponse, UsersResponse, IotpLogsResponse, ConfigResponse, TelemetryEvent
} from './types';
import { ApplianceInformation } from '../components/ApplianceDisplayPanel';

export const UrlForApplianceInfo = (applianceId: string) => {
  return BuildUrl(BASE_URL, {
    path: applianceId,
  });
};

/** @public */
export class EcpAppliancePageClient implements EcpAppliancePageApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly identityApi: IdentityApi;

    constructor(options: {
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
    }
    
    async getApplianceInfo(url: string): Promise<ApplianceInformation> {
        return await this.get(url);
    }

    async getApplianceIds(url: string): Promise<ApplianceIdsResponse> {
        const response = await this.get(url);
        return {
            applianceIds: response.body,
            status: response.status
        }
    }

    async getApplianceInfos(applianceIds: string[]): Promise<ApplianceInformationResponse[]> {
        const applianceInfoArray: ApplianceInformationResponse[] = [];
        for (const applianceId of applianceIds) {
            const url = UrlForApplianceInfo(applianceId);
            const response = await this.get(url);
            const applianceInformationResponse: ApplianceInformationResponse = {
                applianceInfo: response.body,
                status: response.status,
            };
            applianceInfoArray.push(applianceInformationResponse);
        }
        return applianceInfoArray;
    }

    async getApplianceState(applianceId: string): Promise<ApplianceStateResponse> {
        const response = await this.get(BuildUrl(BASE_URL, {
            path: `${applianceId}/state`,
        }));
        return {
            applianceState: response.body,
            status: response.status,
        };
    }

    async getUsers(applianceId: string): Promise<UsersResponse> {
        const response = await this.get(BuildUrl(BASE_URL, {
            path: `${applianceId}/users`,
        }));
        return {
            users: response.body,
            status: response.status,
        };
    };

    async getIotpLogs(applianceId: string): Promise<IotpLogsResponse> {
        const response = await this.get(BuildUrl(BASE_URL, {
            path: `${applianceId}/iotp-logs`,
        }));
        return {
            iotpLogs: response.body,
            status: response.status,
        };
    };

    async getConfig(applianceId: string): Promise<ConfigResponse> {
        const response = await this.get(BuildUrl(BASE_URL, {
            path: `${applianceId}/config`,
        }));
        return {
            config: response.body,
            status: response.status,
        };
    };

    async getConnectivityEvents(applianceId: string): Promise<ConnectivityEventResponse> {
        const response = await this.get(BuildUrl(BASE_URL, {
            path: `${applianceId}/connectivity-events`,
        }));
        return {
            connectivityEvents: response.body,
            status: response.status,
        };
    };

    async getTelemetryEvents(applianceId: string, startDateTime: string, endDateTime: string): Promise<TelemetryEventResponse> {
        const response = await this.get(BuildUrl(BASE_URL, {
            path: `${applianceId}/telemetry-events`,
            queryParams: {
            from: startDateTime,
            to: endDateTime,
            },
        }));
        const data: TelemetryEvent[] = await response.body;
        // Convert iso time to local time to display them in user-friendly way inside the table
        data.map(event => {
            event.time = new Date(`${event.time}Z`).toLocaleString();
            return event;
        });
        return {
            events: data,
            status: response.status,
        };
    };

    async get(path: string): Promise<any> {
        const url = `${await this.discoveryApi.getBaseUrl('proxy')}${path}`;
        const { token: idToken } = await this.identityApi.getCredentials();
        const response = await fetch(url, {
            headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
        });

        if (!response.ok) {
            const payload = await response.text();
            const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
            throw new Error(message);
        }

        const body = await response.json();
        return {status: response.status, body};
    }
}