import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { OrgChartApiClient, orgChartApiRef } from '../api';

export const orgChartPlugin = createPlugin({
  id: 'org-chart',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: orgChartApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new OrgChartApiClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const OrgChartPage = orgChartPlugin.provide(
  createRoutableExtension({
    name: 'OrgChartPage',
    component: () =>
      import('./components/TeamsChartComponent').then(m => m.TeamsChart),
    mountPoint: rootRouteRef,
  }),
);

export const EntityUserProfileCard = orgChartPlugin.provide(
  createComponentExtension({
    name: 'EntityUserProfileCard',
    component: {
      lazy: () => import('./components').then(m => m.UserProfileCard),
    },
  }),
);
export const EntityAwsAccessCard = orgChartPlugin.provide(
  createComponentExtension({
    name: 'EntityAwsAccessCard',
    component: {
      lazy: () => import('./components').then(m => m.AwsAccessCard),
    },
  }),
);

export const OrgTeamsPage = orgChartPlugin.provide(
  createRoutableExtension({
    name: 'OrgTeamsPage',
    component: () =>
      import('./components/TeamsComponent').then(m => m.TeamsMgmt),
    mountPoint: rootRouteRef,
  }),
);
