/**
 * The (primary) user email. Also used by the Microsoft auth provider to resolve the User entity.
 *
 * @public
 */
export const MICROSOFT_EMAIL_ANNOTATION = 'microsoft.com/email';

/**
 * The Idp annotations
 *
 * @public
 */
export const IDP_ORGANIZATION_ID_ANNOTATION =
  'idp.electrolux.com/organization-id';
export const IDP_PROJECT_ID_ANNOTATION = 'idp.electrolux.com/project-id';
export const IDP_COMPONENT_ID_ANNOTATION = 'idp.electrolux.com/service-id';
export const IDP_RESOURCE_ID_ANNOTATION = 'idp.electrolux.com/resource-id';
export const IDP_CONFIG_ID_ANNOTATION = 'idp.electrolux.com/config-id';
export const IDP_UI_TABS_ANNOTATION = 'idp.electrolux.com/ui-tabs';

/**
 * The user id used by the Microsoft Graph API
 *
 * @public
 */

export const DEFAULT_NAMESPACE = 'default';

export const enum ENTITY_TYPE {
  PROJECT = 'project',
  SERVICE = 'service',
  AWS_ACCOUNT = 'aws_account',
  AWS_ENVIRONMENT = 'aws_environment',
  VPC = 'vpc',
  AWS_VPC = 'aws_vpc',
  AWS_VPC_PEERING = 'vpc_peering',
  ATLAS_PROJECT = 'global_atlas_environment',
  ATLAS_VPC_PEERING = 'atlas_peering',
  ATLAS_ENVIRONMENT = 'atlas_environment',
  GLOBAL_ATLAS_ENVIRONMENT = 'global_atlas_environment',
  AWS_VPC_PEERING_REQUESTER = 'vpc-requester',
  AWS_VPC_PEERING_ACCEPTER = 'vpc-accepter',
  AWS_EKS = 'eks',
  AWS_REDIS = 'redis',
  AWS_MSK = 'msk',
  AWS_POSTGRES = 'postgres',
  AWS_S3 = 's3',
  ATLAS_MONGODB = 'atlas_mongodb_cluster',
  MONGODB = 'mongo',
  MONGODB_SERVERLESS = 'atlas_mongodb_serverless_cluster',
  MONGODB_GLOBAL = 'global_mongo',
  OTHER = 'other',
  AWS_TRANSIT_GATEWAY = 'transit_gateway',
  MONGODB_CREDENTIALS = 'mongo_credentials',
  MONGODB_SERVERLESS_CREDENTIALS = 'mongo_serverless_credentials',
}

export const enum ENTITY_STATUS {
  PROVISIONED = 'Provisioned',
  AUTO_DISCOVERED = 'Autodiscovered',
  PENDING_FOR_APPROVAL = 'Provisioning (Pending for approval)',
  PENDING_FOR_APPROVAL_OF_DESTROY = 'Destroy (Pending for approval)',
  PENDING_FOR_APPROVAL_OF_UPDATE = 'Update (Pending for approval)',
  PROVISIONING_APPROVED = 'Provisioning (Approved)',
  PROVISIONING_AUTO_PROMOTED = 'Provisioning (Auto Promoted)',
  DESTROY_AUTO_PROMOTED = 'Destroy (Auto Promoted)',
  DESTROYED = 'Destroyed',
  ERROR = 'Provisioning (Error)',
  UPDATE_ERROR = 'Update (Error)',
  PLAN_ERROR = 'Dry-run (Error)',
  DESTROY_ERROR = 'Destroy (Error)',
  DECLINED = 'Declined',
  PROVISIONING_QUEUED = 'Provisioning (Queued)',
  DESTROY_QUEUED = 'Destroy (Queued)',
  DESTROY_APPROVED = 'Destroy (Approved)',
  UPDATE = 'Update (Approved)',
  PROVISIONING_IN_PROGRESS = 'Provisioning (In progress)',
  DESTROY_IN_PROGRESS = 'Destroy (In progress)',
  UPDATE_IN_PROGRESS = 'Update (In progress)',
  UPDATE_AUTO_PROMOTED = 'Update (Auto Promoted)',
  DRY_RUN_IN_PROGRESS = 'Dry-run (In progress)',
  DRY_RUN_QUEUED = 'Dry-run (Queued)',
  BLUEPRINT_DEPENDENCY = 'Blueprint dependency',
}
