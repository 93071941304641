import {
  ScanResult,
  ScanResultMetadata,
  ScanResultValue,
} from '../api/types/scan.types';
import { TestResultStatus } from '../api/types/scantype.datatype';
import { TestResult } from './types/scan-types-ueswa';
import { SastScanNumbers, SastScanResult } from '../api/types/scan-types-sast';
import { Branch } from '../dto/project.types';
import { SbomScanResult } from './types/scan-types-sbom';
import { Source } from './types/scantype.datatype';

export function getScanResultsFromRawSbomScanResults(
  results: SbomScanResult[],
): ScanResult[] {
  const scanResults = [] as ScanResult[];
  results.forEach(result => {
    const res = {
      scanDate: result.date.slice(0, 10),
      data: [
        {
          id: '0',
          label: result.scanResult.scanResultStatus,
        },
      ] as ScanResultValue[],
      firmware: {
        label: 'Firmware',
        value: result.scanResult.firmware,
      } as ScanResultMetadata,
      fwVersion: {
        label: 'Version',
        value: result.scanResult.firmwareVersion,
      } as ScanResultMetadata,
      description: {
        label: 'Scan result policy',
        value: result.scanResult.scanResultPolicy,
      } as ScanResultMetadata,
    } as ScanResult;
    scanResults.push(res);
  });
  return scanResults;
}

export function getScanResultsFromRawSastScanResults(
  sastScanResults: SastScanResult[],
  branch: Branch,
) {
  return sastScanResults?.map((result: SastScanResult) => {
    const data = [] as ScanResultValue[];
    result.scanResult.map((scanResultItem: SastScanNumbers) =>
      data.push({
        id: `${result.id}_${scanResultItem.severity}`,
        value: scanResultItem.count,
        label: scanResultItem.severity,
      } as ScanResultValue),
    );
    return {
      branch: branch,
      data: data,
    } as ScanResult;
  });
}

export function getScanResultsFromRawTestResults(testResults: TestResult[]) {
  return testResults?.map((result: TestResult) => {
    const data = [] as ScanResultValue[];

    data.push({
      id: `${TestResultStatus.FAILED}_${result.results.failed}`,
      value: result.results.failed,
      label: TestResultStatus.FAILED,
    } as ScanResultValue);

    data.push({
      id: `${TestResultStatus.PASSED}_${result.results.passed}`,
      value: result.results.passed,
      label: TestResultStatus.PASSED,
    } as ScanResultValue);

    data.push({
      id: `${TestResultStatus.SKIPPED}_${result.results.skipped}`,
      value: result.results.skipped,
      label: TestResultStatus.SKIPPED,
    } as ScanResultValue);

    if (result.source === Source.X_RAY) {
      data.push({
        id: `${TestResultStatus.ABORTED}_${result.results.aborted}`,
        value: result.results.skipped,
        label: TestResultStatus.ABORTED,
      } as ScanResultValue);

      data.push({
        id: `${TestResultStatus.BLOCKED}_${result.results.blocked}`,
        value: result.results.skipped,
        label: TestResultStatus.BLOCKED,
      } as ScanResultValue);

      data.push({
        id: `${TestResultStatus.TODO}_${result.results.todo}`,
        value: result.results.skipped,
        label: TestResultStatus.TODO,
      } as ScanResultValue);
    }

    return {
      data: data,
      url: result.url,
      fwVersion: {
        label: 'FW Version',
        value: result?.results?.metadata.firmwareVersion,
      } as ScanResultMetadata,
      testLibVersion: {
        label: 'TestLib Version',
        value: result?.results?.metadata.testLibVersion,
      } as ScanResultMetadata,
      source: {
        label: 'Source',
        value: result?.source,
      } as ScanResultMetadata,
    } as ScanResult;
  });
}
