import { createApiRef } from '@backstage/core-plugin-api';
import {
  ApplianceStateResponse,
  ApplianceCapabilitiesResponse,
  CommandResponse,
  WhitelistedPropertiesResponse,
  SearchAppliancesIdentityInfoByIdPartsResponse,
  UsersResponse,
} from './types';

/** @public */
export const OcpAppliancePageApiRef = createApiRef<OcpAppliancePageApi>({
  id: 'plugin.ocp-appliance-page.service',
});

/** @public */
export interface OcpAppliancePageApi {
  get(path: string): Promise<any>;
  getApplianceState(applianceId: string): Promise<ApplianceStateResponse>;
  getApplianceCapabilities(
    applianceId: string,
  ): Promise<ApplianceCapabilitiesResponse>;
  getECPApplianceState(applianceId: string): Promise<ApplianceStateResponse>;
  getApplianceCommands(
    applianceId: string,
    limit: number,
  ): Promise<CommandResponse>;
  getWhitelistedProperties(
    applianceType: string,
  ): Promise<WhitelistedPropertiesResponse>;
  searchAppliancesIdentityInfoByIdParts(
    pnc: string,
    elc: string,
    sn: string,
    mac: string,
  ): Promise<SearchAppliancesIdentityInfoByIdPartsResponse>;
  getUsers(applianceId: string): Promise<UsersResponse>;
}
