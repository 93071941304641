import {
  DiscoveryApi,
  IdentityApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import axios, { AxiosInstance } from 'axios';
import {
  Branch,
  Project,
  ScanResult,
  ScanSummary,
} from '../api/types/scan.types';
import {
  getScanResultsFromRawSastScanResults,
  getScanResultsFromRawSbomScanResults,
  getScanResultsFromRawTestResults,
} from './api.util';

/**
 * Wrapper around Axios which adds interceptor for adding Phoenix token as bearer
 * and resolves proxy URL for path.
 */
export class AdpPhoenixServiceApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  readonly apiClient: AxiosInstance;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;

    this.apiClient = axios.create({
      withCredentials: true,
    });

    // associate interceptor middleware
    this.apiClient.interceptors.request.use(async request => {
      // URL = resolve proxy URL
      request.url = `${await this.discoveryApi.getBaseUrl('proxy')}${
        request.url
      }`;
      // Use credentials as bearer token
      const { token } = await this.identityApi.getCredentials();
      if (!request.headers) {
        request.headers = {};
      }
      request.headers.Authorization = `Bearer ${token}`;
      return request;
    });
  }

  async get(path: string): Promise<any> {
    return this.apiClient
      .get(`/adp/lambda${path}`)
      .then(response => response.data)
      .catch(() => {
        return undefined;
      });
  }

  async getProjects(categoryName: string): Promise<Project[]> {
    const projects = await this.get(`/projects?search=${categoryName}`);

    return projects?.filter(
      (project: Project) => project.productCategory === categoryName,
    );
  }

  async getScanSummaries(categoryName: string): Promise<ScanSummary[]> {
    return await this.get(
      `/projects/product-category/${categoryName}/scan-summary`,
    );
  }

  async getSastScanResults(
    projectId: string,
    branches: Branch[],
  ): Promise<ScanResult[]> {
    const sastScanResultsPromises = branches.map(branch => {
      return this.get(
        `/projects/${projectId}/branches/${branch.id}/sast-scan-results`,
      );
    });

    const sastScanResults = await Promise.all(sastScanResultsPromises);

    return sastScanResults
      ?.map((sastScanResult, index) => {
        return getScanResultsFromRawSastScanResults(
          sastScanResult,
          branches[index],
        );
      })
      .flat();
  }

  async getFunctionalTestResults(projectId: string): Promise<ScanResult[]> {
    const testResults = await this.get(`/projects/${projectId}/test-results`);

    return getScanResultsFromRawTestResults(testResults);
  }

  async getSbomScanResults(projectId: string): Promise<ScanResult[]> {
    const sbomScanResult = await this.get(
      `/projects/${projectId}/sbom-scan-results`,
    );

    return getScanResultsFromRawSbomScanResults(sbomScanResult);
  }
}

export const adpPhoenixServiceApiRef = createApiRef<AdpPhoenixServiceApi>({
  id: 'plugin.adp-phoenix-service-lambda.service',
});
