import { DiscoveryApi, createApiRef } from '@backstage/core-plugin-api';
import { DocumentationCard } from '../dto/documentation.types';
import {
  Branch,
  Project,
  ScanResult,
  ScanResultMetadata,
  ScanResultValue,
  ScanSummary,
} from './types/scan.types';
import { Document } from './types/document.types';
import { PDCLProjectData } from './types/pdcl.project.types';
import { AdpApi } from './adpApi';
import { getConfluencePages } from './getConfluencePages';
import { PDCLDeliverable } from '../dto/pdcl-deliverable.types';
import { getPDCLDeliverables } from './getPDCLDeliverables';
import { ScanType, TestResultStatus } from './types/scantype.datatype';
import { SastScanNumbers, SastScanResult } from './types/scan-types-sast';
import { TestResult } from './types/scan-types-ueswa';
import { SbomScanResult } from './types/scan-types-sbom';

export class AdpApiClient implements AdpApi {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = options.discoveryApi;
  }

  async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('adp')}${path}`;
    const response = await fetch(url);

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    return await response.json();
  }

  async getPages(
    confluenceSpaceId: string,
    titleLabelPrefix: string,
  ): Promise<DocumentationCard[]> {
    return await this.get(
      `/documents/confluence/${confluenceSpaceId}/pages?details=true`,
    )
      .then(doc =>
        Array.prototype.concat(
          ...doc.map((docItem: Document) => {
            return getConfluencePages(
              docItem,
              [] as DocumentationCard[],
              titleLabelPrefix,
            );
          }),
        ),
      )
      .catch(() => [] as DocumentationCard[]);
  }

  async getPageById(confluenceSpaceId: string, pageId: string): Promise<any> {
    const resp = await this.get(
      `/documents/confluence/${confluenceSpaceId}/pages/${pageId}`,
    );
    return resp;
  }

  async getProjectData(
    confluenceSpaceId: string,
    pdclLabel: string,
  ): Promise<PDCLDeliverable[]> {
    return await this.get(
      `/pdcl-projects/spaces/${confluenceSpaceId}/labels/${pdclLabel}`,
    )
      .then(data =>
        getPDCLDeliverables(data as PDCLProjectData[], [] as PDCLDeliverable[]),
      )
      .catch(() => [] as PDCLDeliverable[]);
  }

  async getDocumentationData(
    confluenceSpaceId: string,
    documentationLabel: string,
    titleLabelPrefix: string,
  ): Promise<DocumentationCard[]> {
    return await this.get(
      `/documents/spaces/${confluenceSpaceId}/labels/${documentationLabel}`,
    )
      .then(doc =>
        Array.prototype.concat(
          ...doc.map((docItem: Document) => {
            return getConfluencePages(
              docItem,
              [] as DocumentationCard[],
              titleLabelPrefix,
            );
          }),
        ),
      )
      .catch(() => [] as DocumentationCard[]);
  }

  async getScanResults(
    projectId: string,
    branches: Branch[],
    scanType: ScanType,
  ): Promise<ScanResult[]> {
    switch (scanType) {
      case ScanType.SAST: {
        const scanResults = [] as ScanResult[];
        for (const branch of branches) {
          const scanResult = await this.get(
            `/projects/${projectId}/branches/${branch.id}/sast-scan-results?limit=1`,
          ).then((result: SastScanResult[]) => {
            const data = [] as ScanResultValue[];
            result[0].scanResult.forEach((scanResultItem: SastScanNumbers) =>
              data.push({
                id: `${result[0].id}_${scanResultItem.severity}`,
                value: scanResultItem.count,
                label: scanResultItem.severity,
              } as ScanResultValue),
            );
            return {
              branch: branch,
              data: data,
              source: {
                label: 'Source',
                value: result[0].source,
              } as ScanResultMetadata,
            } as ScanResult;
          });
          scanResults.push(scanResult);
        }
        return scanResults;
      }
      case ScanType.FUNCTIONAL_TEST: {
        const scanResults = [] as ScanResult[];
        for (const branch of branches) {
          const scanResult = await this.get(
            `/projects/${projectId}/branches/${branch.id}/test-results?limit=1`,
          ).then((result: TestResult[]) => {
            const data = [] as ScanResultValue[];
            data.push({
              id: `${TestResultStatus.FAILED}_${result[0].results.failed}`,
              value: result[0].results.failed,
              label: TestResultStatus.FAILED,
            } as ScanResultValue);

            data.push({
              id: `${TestResultStatus.PASSED}_${result[0].results.passed}`,
              value: result[0].results.passed,
              label: TestResultStatus.PASSED,
            } as ScanResultValue);

            data.push({
              id: `${TestResultStatus.SKIPPED}_${result[0].results.skipped}`,
              value: result[0].results.skipped,
              label: TestResultStatus.SKIPPED,
            } as ScanResultValue);

            const metadata = JSON.parse(result[0]?.results?.metadata);
            return {
              branch: branch,
              data: data,
              url: result[0].url,
              fwVersion: {
                label: 'FW Version',
                value: metadata.firmwareVersion,
              } as ScanResultMetadata,
              testLibVersion: {
                label: 'TestLib Version',
                value: metadata.testLibVersion,
              } as ScanResultMetadata,
              source: {
                label: 'Source',
                value: result[0].source,
              } as ScanResultMetadata,
            } as ScanResult;
          });
          scanResults.push(scanResult);
        }
        return scanResults;
      }
      case ScanType.SBOM: {
        const scanResults = [] as ScanResult[];
        for (const branch of branches) {
          const scanResult = await this.get(
            `/projects/${projectId}/branches/${branch.id}/sbom-scan-results?limit=1`,
          ).then((results: SbomScanResult[]) => {
            const result = results[0];
            const res = {
              scanDate: result.date.slice(0, 10),
              branch,
              data: [
                {
                  id: '0',
                  label: result.results.scanResultStatus,
                },
              ] as ScanResultValue[],
              firmware: {
                label: 'Firmware',
                value: result.results.firmware,
              } as ScanResultMetadata,
              fwVersion: {
                label: 'Version',
                value: result.results.firmwareVersion,
              } as ScanResultMetadata,
              branchName: {
                label: 'Branch',
                value: branch.name,
              } as ScanResultMetadata,
              description: {
                label: 'Violated policy',
                value: result.results.scanResultPolicy,
              } as ScanResultMetadata,
            } as ScanResult;
            return res;
          });
          scanResults.push(scanResult);
        }
        return scanResults;
      }
      default: {
        return [] as ScanResult[];
      }
    }
  }

  async getProjects(categoryName: string): Promise<Project[]> {
    return await this.get(`/projects?search=${categoryName}`).then(data =>
      data.filter(
        (project: Project) => project.productCategory === categoryName,
      ),
    );
  }

  async getScanSummary(categoryName: string): Promise<ScanSummary[]> {
    return (await this.get(
      `/projects/product-category/${categoryName}/scan-summary`,
    )) as ScanSummary[];
  }
}

export const adpApiRef = createApiRef<AdpApiClient>({
  id: 'plugin.adp-phoenix-service.service',
});
